import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Hamburger from "./Hamburger";
const Header = () => {
  const location = useLocation();

  const [menuState, setMenuState] = useState({
    clicked: false,
    menuName: "",
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setMenuState({
      clicked: false,
      menuName: "Menu",
    });
  }, [location]);

  const handleMenu = () => {
    disableMenu();
    setMenuState((prevState) => ({
      clicked: !prevState.clicked,
      menuName: prevState.clicked ? "Menu" : "Close",
    }));
  };

  const disableMenu = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
      <Box
          component="header"
          sx={{
            backgroundColor: "#333",
            color: "red",
          }}
      >
        <Box
            padding= "25px 20px"
        display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
          {/* Logo */}
          <Typography variant="h6" sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
            <Link to="/" style={{color: "#ff0000", textDecoration: "none", fontWeight:'700'}}>
              KJ GROUPS
            </Link>
          </Typography>

          {/* Menu Button */}
          <IconButton
              sx={{color: "#ff0000"}}
              onClick={handleMenu}
              disabled={disabled}
          >

            <MenuIcon sx={{fontSize: 30}}/>
          </IconButton>
        </Box>

        {/* Hamburger Menu */}
        <Hamburger state={menuState} handleMenu={handleMenu}/>
      </Box>
  );
};
export default Header;