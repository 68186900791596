// home.js

import React from "react";
import "../App.scss";
import bgImage from "../assets/images/heroBg2.png"
function Hero2() {
  return (
    <>
        <div id="app"  className="hero-container">
        <div className="title">
          <div className="title-inner" style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', height:'100vh'}}>
            <div className="cafe">
              <div className="cafe-inner" style={{color:'#ff0000',  textShadow: "2px 4px 5px rgba(0, 0, 0, 1)"}}>KJ GROUPS</div>
            </div>

            <div className="mozart">
              <div className="mozart-inner" style={{fontSize:'24px', fontWeight:'500', color:'white'}}>We are creating a unique franchise business model that aims for mutual growth. </div>
            </div>
          </div>
        </div>
        {/*<div className="image">*/}
        {/*  <img*/}
        {/*    src={bgImage}*/}
        {/*    alt=""*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </>
  );
}

export default Hero2;
