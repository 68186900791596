import React, { useState } from 'react';
import { motion } from 'framer-motion';
import img from '../assets/images/hero.jpeg';
import img2 from '../assets/images/3.jpg';
import img3 from '../assets/images/contactbg.jpeg';
import img4 from '../assets/images/1.jpeg';
import img5 from '../assets/images/2.jpg';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Add this line
function Carousel() {
    const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);

    const handleNext = () => {
        setPositionIndexes((prev) => {
            const next = prev.map((item) => (item + 1) % 5);
            return next;
        });
    };

    const handlePrev = () => {
    setPositionIndexes((prev) => {
        const next = prev.map((item) => (item - 1 + 5) % 5);
        return next;
    });
};

    const images = [img, img2, img3, img4, img5];
    const positions = ['center', 'left1', 'left', 'right', 'right1'];

    const ImageVariants = {
        center: {
            opacity: 1,
            x: '0%',
            scale: 1,
            zIndex: 5,
        },
        left1: {
            opacity: 0.8,
            x: '-50%',
            scale: 0.7,
            zIndex: 3,
        },
        left: {
            opacity: 0.6,
            x: '-100%',
            scale: 0.5,
            zIndex: 2,
        },
        right: {
            opacity: 0.6,
            x: '100%',
            scale: 0.5,
            zIndex: 1,
        },
        right1: {
            opacity: 0.8,
            x: '50%',
            scale: 0.7,
            zIndex: 3,
        },
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', position: 'relative', width: '100%', height: '350px', overflow: 'hidden' }}>
            {images.map((image, index) => (
                <motion.img
                    key={index}
                    src={image}
                    alt='carousel'
                    style={{ borderRadius: '12px', width: '350px', position: 'absolute',height: '350px',objectFit: 'cover' }}
                    initial='center'
                    animate={positions[positionIndexes[index]]}
                    variants={ImageVariants}
                    transition={{ duration: 0.5 }}
                />
            ))}
            <IconButton onClick={handlePrev} style={{ position: 'absolute', left: '15%', zIndex: 7, backgroundColor: "white" }} className="carousel-button prev">
                <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={handleNext} style={{ position: 'absolute', right: '15%', zIndex: 7, backgroundColor: "white" }} className="carousel-button next">
                <ArrowForwardIcon />
            </IconButton>
        </div>
    );
}

export default Carousel;