import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="#fff">
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://kjgroups.co.uk/">
        KJ Groups
      </Link>
      {", All rights reserved. Developed by "}
      <Link color="inherit" href="https://devclubm.com">
        DevClub
      </Link>
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function StickyFooter() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 10,
          backgroundColor: "#000",
          color: "#fff",
        }}
      >
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              fontFamily: "monospace",
              fontWeight: 700,
              textDecoration: "none",
              mb: 3,
              mt: 1,
                color:'#ff0000',  textShadow: "2px 4px 5px rgba(0, 0, 0, 1)"
            }}
          >
            KJGROUPS
          </Typography>
          <div style={{ marginBottom: "30px", marginTop: "25px" }}>
            <Typography variant="body1" color="#fff">
              Affiliate Inquiry:{" "}
              <Link
                href="mailto:contact@kjgroups.co.uk"
                variant="body1"
                color="#fff"
              >
                contact@kjgroups.co.uk
              </Link>{" "}
            </Typography>
          </div>
          <Copyright />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
