import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  staggerText,
  staggerReveal,
  fadeInUp,
  handleHover,
  handleHoverExit,
  staggerRevealClose,
} from "./Animation";

const Hamburger = ({ state, handleMenu }) => {
  // Create references for our DOM nodes
  let menuLayer = useRef(null);
  let reveal1 = useRef(null);
  let reveal2 = useRef(null);
  let cityBackground = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let info = useRef(null);

  useEffect(() => {
    // If the menu is closed
    if (state.clicked === false) {
      staggerRevealClose(reveal2, reveal1);
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    } else {
      // If the menu is open
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
      gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
        height: "100%",
      });
      staggerReveal(reveal1, reveal2);
      fadeInUp(info);
      staggerText(line1, line2, line3);
    }
  }, [state]);

  return (
    <div ref={(el) => (menuLayer = el)} className="hamburger-menu">
      <div
        ref={(el) => (reveal1 = el)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={(el) => (reveal2 = el)} className="menu-layer">
        <div
          ref={(el) => (cityBackground = el)}
          className="menu-city-background"
        ></div>
        <div className="container">
          <IconButton
              onClick={handleMenu}
              sx={{
                position: "absolute",
                top: 20,
                right: 20,
                color: "white",
              }}
          >
            <CloseIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <div className="wrapper">
            <div className="menu-links">
              <nav>
                <ul>
                  <li>
                    <Link
                      onMouseEnter={(e) => handleHover(e)}
                      onMouseOut={(e) => handleHoverExit(e)}
                      ref={(el) => (line1 = el)}
                      to="/"
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <a
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                      ref={(el) => (line2 = el)}
                      href="https://koreanbbq.kjgroups.co.uk/"
                         target="_blank"
                      rel="noopener noreferrer"
                    >
   KOREAN BBQ
</a>
                  </li>
                  <li>
                      <a
                          onMouseEnter={(e) => handleHover(e)}
                          onMouseOut={(e) => handleHoverExit(e)}
                          ref={(el) => (line2 = el)}
                          href="https://koreanmunchies.kjgroups.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          KOREAN MUNCHIES
                      </a>
                  </li>
                    <li>
                        <a
                            onMouseEnter={(e) => handleHover(e)}
                            onMouseOut={(e) => handleHoverExit(e)}
                            ref={(el) => (line2 = el)}
                            href="https://soulsushi.kjgroups.co.uk/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            SOUL SUSHI
                        </a>
                    </li>
                    <li>
                        <Link
                            onMouseEnter={(e) => handleHover(e)}
                            onMouseOut={(e) => handleHoverExit(e)}
                            ref={(el) => (line3 = el)}
                            to="/recruit"
                        >

                        </Link>
                    </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
