import React from 'react';
import { Link } from '@mui/material';
import Phone from '@mui/icons-material/Phone';
import Temp1 from '../assets/images/client3.jpg';
import Temp2 from '../assets/images/client1.jpg';
import Temp3 from '../assets/images/client2.jpg';
import { Fade } from "react-awesome-reveal";

function Cards() {
    return (
        <>
            <div id="header" style={{color:'#ff0000',  textShadow: "2px 4px 5px rgba(0, 0, 0, 1)"}}>
                <h1>OUR RESTAURANTS</h1>
            </div>
                <div className="container">
                    <Fade duration={1500} triggerOnce={true} direction={"left"} >
                    <div className="card">
                        <div className="card-image">
                            <img src={Temp1} alt={'Korean BBQ logo'} />
                        </div>
                        <div className="card-text">
                            <p className="card-meal-type" style={{ display: 'flex', alignItems: 'center' }}>
                                <Link href="tel:01316670200" color="inherit" underline="none" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Phone /> 0131 667 0200
                                </Link>
                            </p>
                            <h2 className="card-title">Korean BBQ</h2>
                            <p className="card-body">Where small and shared plates take center stage. Immerse yourself in the art of Korean BBQ with our built-in table grills, enhancing the joy of shared moments.</p>
                        </div>
                        <div className="card-price">
                            <Link href="https://koreanbbq.kjgroups.co.uk" color="inherit" underline="none">
                                Visit Site
                            </Link>
                        </div>
                    </div>
                    </Fade>
                    <Fade duration={1500} triggerOnce={true} direction={"down"} >
                    <div className="card">
                        <div className="card-image">
                            <img src={Temp2} alt={"Korean Munchies logo"}/>
                        </div>
                        <div className="card-text">
                            <p className="card-meal-type" style={{ display: 'flex', alignItems: 'center' }}>
                                <Link href="tel:01312853038" color="inherit" underline="none" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Phone /> 0131 285 3038
                                </Link>
                            </p>
                            <h2 className="card-title">Korean Munchies</h2>
                            <p className="card-body"> We are the first Korean Street Food Space in the city, offering an easy-going and chilled environment for food enthusiasts to indulge in authentic Korean delights.</p>
                        </div>
                        <div className="card-price">
                            <Link href="https://koreanmunchies.kjgroups.co.uk/" color="inherit" underline="none">
                                Visit Site
                            </Link>
                        </div>
                    </div>
</Fade>
                        <Fade duration={1500} triggerOnce={true} direction={"right"} >

                        <div className="card">
                        <div className="card-image">
                            <img src={Temp3} alt={"Soul Sushi Logo"} />
                        </div>
                        <div className="card-text">
                            <p className="card-meal-type" style={{ display: 'flex', alignItems: 'center' }}>
                                <Link href="tel:01316670200" color="inherit" underline="none" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Phone /> 0131 667 0200
                                </Link>
                            </p>
                            <h2 className="card-title">Soul Sushi</h2>
                            <p className="card-body"> Sushi take-out offering handmade, fresh rolls, nigiri, sashimi, poké bowls & hot katsu curries with great student deals and amazing young atmosphere.</p>
                        </div>
                        <div className="card-price">
                            <Link href="https://soulsushi.kjgroups.co.uk/" color="inherit" underline="none">
                                Visit Site
                            </Link>
                        </div>
                    </div>
                    </Fade>
                </div>
        </>
    );
}

export default Cards;